import React from 'react';
import { Link } from 'react-router-dom';
import { FaBriefcaseMedical } from 'react-icons/fa';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-title">
          <FaBriefcaseMedical className="stethoscope-icon" />
          MABASO TRADE AND SUPPLIES
        </Link>
      </div>


    </nav>
  );
}

export default Navbar;
