import React from 'react';
import './InformationDisplay.css';

function InformationDisplay() {
  return (
    <div className="info-container">
      <div className="info-content">
        <h2>Contact Information</h2>
        <div className="info-item">
          <span>Email:</span>
          <a href="mailto:doniechemical@gmail.com">mabasotradeandsupplies@gmail.com</a>
        </div>
        <div className="info-item">
          <span>Address:</span>
          <p>1 Edgar Road ,Jansenpark,boksburg,1459</p>
        </div>
        <div className="info-item">
          <span>Contact:</span>
          <p>Nkateko - 0765792925</p>
        </div>
      </div>
    </div>
  );
}

export default InformationDisplay;
