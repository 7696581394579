import React from "react";
import "./CustomComponent.css";

const CustomComponent = ({
  header,
  body,
  imageUrl,
  textColor,
  backgroundColor,
}) => {
  const styles = {
    color: textColor || 'white',
    backgroundColor: backgroundColor || '#900C3F',
  };

  return (
    <div className="custom-component" style={styles}>
      <img src={imageUrl} alt="Custom Component" className="image_main"/>
      <div className="info_section">
        <h2>{header}</h2>

        <p>{body}</p>
      </div>
    </div>
  );
};

export default CustomComponent;
