import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import CustomComponent from './components/customComponent/CustomComponent';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import data from './Data';
import InformationDisplay from './components/InformationDisplay/InformationDisplay';

function App() {
  return (
<Router>
      <div className="App">
        <Navbar />
        <Hero/>
        {data.map((item, index) => (
          <CustomComponent
            key={index}
            header={item.header}
            body={item.body}
            imageUrl={item.imageUrl}
            textColor={item.textColor}
            backgroundColor={item.backgroundColor}
          />
        ))}

        <InformationDisplay/>
      </div>
    </Router>
  );
}

export default App;
