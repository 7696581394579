import image_1 from './section_01.jpg';
import image_2 from './image_2.png';

const data = [
  {
    header: 'About Us',
    body:
      "Mabaso Trade and Supplies is a privately owned South African company that was established following the country's transition to democracy. With an extensive customer base spanning across Africa, Mabaso Trade and Supplies specializes in the distribution of various medical goods to institutions and patients, and exclusively provides medicines to registered vendors.",
    imageUrl: image_1,
    textColor: 'white', // Replace with your desired text color
    backgroundColor: 'rgba(86, 102, 122, 0.9)', // Replace with your desired background color
  },
  {
    header: 'Introducing Our Products',
    body:
      "At Mabaso Trade and Supplies, we offer a diverse range of services tailored to meet varying needs. Our offerings include providing adult nappies for individual patients as well as delivering medical equipment to large companies and hospitals. Whether it's a single item or a complete refurbishment of units and wards, we ensure top-quality service. Serving customers across South Africa and Southern Africa, we rely on reliable courier services and our own efficient delivery system to meet your requirements.",
    imageUrl: image_2,
    textColor: 'white', // Replace with your desired text color
    backgroundColor: 'rgba(86, 102, 122, 0.9)', // Replace with your desired background color
  },
];

export default data;
