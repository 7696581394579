import React from "react";
import "./Hero.css";

function Hero() {
  return (
    <header className="hero-header">
      <div className="overlay">
      <h1>Mbaso Trade and Supplies</h1>
<h3>Your Dependable Medical Distributors</h3>
<p>"Ensuring Health with Precision - Your Trusted Medical Distributors. We guarantee seamless supply chain solutions for life-saving essentials, reaching every corner with care and efficiency."</p>


        <br />
      </div>
    </header>
  );
}

export default Hero;
